<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},

  props: {},

  directives: {},

  data: function () {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss"></style>
